// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/Index.vue'),
      children: [
        {
          path: '',
          alias: '/home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: '/debito',
          component: () => import('@/views/debito/Index.vue'),
        },
        {
          path: '/aclaraciones',
          component: () => import('@/views/aclaraciones/Index.vue'),
        },
        {
          path: '/monitoreo',
          component: () => import('@/views/monitoreo/Index.vue'),
        },
        {
          path: '/saldos',
          component: () => import('@/views/saldos/Index.vue'),
        },
        {
          path: '/expenses',
          component: () => import('@/views/expenses/Index.vue'),
        },
        {
          path: '/acquirer',
          component: () => import('@/views/acquirer/Index.vue'),
        },
        {
          path: 'contact-us',
          component: () => import('@/views/contact-us/Index.vue'),
        },
        {
          path: 'partners',
          component: () => import('@/views/partners/Index.vue'),
        },
        {
          path: '*',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
