// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#1973b8',//claro
        secondary: '#003D74',//fuerte
        accent: '#BDBDBD',//medio
        btn_color: '#028484',
        appbar_color: '#2D5980',
        //"layout-appbar__appbar": "#ffffff",
      },
      dark: {
        primary: '#50778D',
        secondary: '#0B1C3D',
        accent: '#204165',
      },
    },
  },
})
